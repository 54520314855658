<template>
  <div>
    <b-modal
      id="modal-danger"
      v-model="isDeleteModalActive"
      ok-variant="danger"
      ok-title="Accept"
      modal-class="modal-normal"
      centered>
      <div class="d-block text-center">
        <p class="font-weight-medium text-28">
          Confirm to Delete
        </p>
      </div>
      <div
        class="mx-auto"
        style="width: 80%;">
        <b-row>
          <b-col
            cols="6"
            class="d-flex justify-content-end">
            <span class="font-weight-bolder">ID :</span>
          </b-col>
          <b-col cols="6">
            {{ emailTemplateId }}
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-end">
            <span class="font-weight-bolder">Name :</span>
          </b-col>
          <b-col cols="6">
            {{ emailTemplate.templateName }}
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{}">
        <div class="mx-auto">
          <b-button
            variant="outline-danger"
            style="margin-right: 12px"
            @click="confirmDelete(emailTemplateId)">
            Confirm
          </b-button>
          <b-button
            variant="primary"
            @click="cancelDeleteModal()">
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="EmailTemplateDetailForm">
          <b-form
            @submit.prevent="handleSubmit()"
            @reset.prevent="resetForm()">
            <p class="text--weight">
              Email Template Detail
            </p>
            <b-row>
              <b-col
                sm="12"
                md="8">
                <TextFieldInput
                  v-model="emailTemplate.templateName"
                  :rules="{ required: false }"
                  name="Template Name"
                  label="Template Name"
                  placeholder="Template Name"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="8">
                <TextFieldInput
                  v-model="emailTemplate.subject"
                  :rules="{ required: false }"
                  name="Subject"
                  label="Subject"
                  placeholder="Subject"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="12">
                <b-form-group
                  label="Message">
                  <wysiwyg
                    v-model="emailTemplate.message"
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col
                sm="12"
                md="12">
                <b-form-group
                  label="Remark"
                >
                  <wysiwyg
                    v-model="emailTemplate.remark"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="8">
                <SelectField
                  v-model="emailTemplate.emailGroupId"
                  :options="emailGroup"
                  :rules="{ required: false }"
                  name="Email Group Id"
                  form-label="Email Group ID"
                  label-option="groupName"
                  return-value="id" />
              </b-col> -->
            </b-row>
            <StatusRadioButton :status.sync="emailTemplate.status" />
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2 btn-submit"
                type="submit"
                @click="editEmailTemplate(emailTemplate)">
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="btn-submit"
                variant="outline-secondary"
                @click="backRoute()">
                Cancel
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon ml-auto"
                @click="openDeleteModal()">
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import StatusRadioButton from '@/components/Form/StatusRadioButton.vue'
import EmailTemplateProvider from '@/resources/EmailTemplateProvider'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const EmailTemplateService = new EmailTemplateProvider()

export default {
  components: {
    TextFieldInput,
    StatusRadioButton
  },
  data () {
    return {
      isDeleteModalActive: false,
      emailTemplate: {
        templateName: '',
        subject: '',
        message: '',
        status: ''
      }
    }
  },
  computed: {
    emailTemplateId () {
      return this.$route.params.id
    }
  },
  created () {
    this.getEmailTemplateById(this.emailTemplateId)
  },
  methods: {
    async getEmailTemplateById (id) {
      const { data } = await EmailTemplateService.findOne(id)
      this.emailTemplate.templateName = data.templateName
      this.emailTemplate.subject = data.subject
      this.emailTemplate.message = data.message
      this.emailTemplate.status = data.status
      console.log('data', data)
    },
    async editEmailTemplate (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const data = await EmailTemplateService.update(this.emailTemplateId, payload)
        console.log('data', data)
        this.$router.push({ name: 'email-template' })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Email Template has been updated'
          }
        })
      } catch (err) {
        console.error(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Email Template has been Failed'
          }
        })
      }
    },
    async confirmDelete (val) {
      try {
        await EmailTemplateService.softDelete(val)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Email Template has been deleted'
          }
        })
        this.$router.push({ name: 'email-template' })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Email Template has been Failed ${error}`
          }
        })
      }
    },
    backRoute () {
      this.$router.push({ name: 'email-template' })
    },
    openDeleteModal () {
      this.isDeleteModalActive = true
    },
    cancelDeleteModal () {
      this.isDeleteModalActive = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
